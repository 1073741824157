<template>
  <div class="GameList">
    <div class="GameList_small">
        <div v-if="msgs">
            <div class="GameList_small_items" v-for="item in msgs" :key="item.name">
                    <a :href="`${$qdAddress}/#/GameDetail?name=`+item.name">
                    <div class="GameList_small_items_img">
                        <img :src="item.icon" alt="">
                    </div>
                    <div class="GameList_small_items_title">
                        {{ item.name }}
                    </div>
                </a>
            </div>
        </div>
        </div>
  </div>
</template>

<script>
export default {
props:['msgs']
}
</script>

<style>
.GameList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 10em;

}

.GameList_small {
    overflow: hidden;
    width: 60%;
    height: 100%;
    display: block;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.GameList_small_items {
    margin: 1em;
    float: left;
    margin-left: 2em; /* 间距 */
    margin-right: 2em; /* 间距 */
    box-sizing: border-box; /* 边框计算在宽度内 */
    width: 6em;
    height: 6em;
}

.GameList_small_items_img {
    width: 100%;
    overflow: hidden;
}

.GameList_small_items_img img {
    width: 100%;
    display: block; /* 去除图片底部空白 */
    border-radius: 4px; /* 圆角效果 */
}

.GameList_small_items_title {
    text-align: center;
    font-size: 0.8rem; /* 字体大小，根据需要调整 */
    color: #333;
    padding: 0.2em 0; /* 标题上下内边距 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>