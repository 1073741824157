<template>
  <div class="app">
    <PageHeader v-if="!isPE"></PageHeader>
    <PEHeader v-if="isPE"></PEHeader>
    <div id="main" :style="'top:' + this.myTop">
      <router-view id="rv">获取信息失败</router-view>
    </div>

    <PageFooter :bgc="this.footerBgc"></PageFooter>
  </div>
</template>
<script>
export default {
  created() {
    // axios 配置代理，防止跨域
  },
  mounted() {
    //如果是手机端访问的不是手机端页面，则跳转
    if (this.isPE)
      if (this.$router.currentRoute.path != "/GameList")
        if (this.$router.currentRoute.path != "/GameDetail")
          if (this.$router.currentRoute.path != "/NewsDetail")
            if (this.$router.currentRoute.path != "/NewsAndInformation") {
              this.$router.push("/PE");
            }
  },
  data() {
    return {
      activeIndex: "1",
    };
  },
  computed: {
    myTop() {
      return this.isPE ? "0" : "6em";
    },
    footerBgc() {
      return "background:" + (this.isPE ? "#363636" : "#e24128");
    },
    isPE() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      //如果是手机端访问，则看作是手机端
      if (flag) {
        return true;
      }
      return false;
    },
  },
  methods: {},
};
</script>

<style>
* {
  text-decoration: none;

  margin: 0;
  padding: 0;
}

#main {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

.app {
  width: 100%;
  font-family: Arial, sans-serif;
  background-color: #dddddd;
  min-height: 100vh;
}

#rv {
  margin: 0;
  margin-top: 0;
  border-radius: 6px;
  width: 100%;
  background: white;
}
</style>
