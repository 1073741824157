<template>
  <div>
    <!-- 导航栏 -->
    <el-menu :default-active="activeIndex2" class="navbar " mode="horizontal" @select="handleSelect"
      background-color="#e24128" text-color="#fff" active-text-color="#fff"
      style="position: fixed; top: 0; width: 100%; z-index: 1000;">

      <!-- Logo 图片 -->
      <div class="logo">
        <img src="../../public/images/FengLinlogo.png" alt="Logo">
      </div>

      <el-menu-item index="1" :style="fontSize"><a href="#/">首页</a></el-menu-item>
      <el-menu-item index="2" :style="fontSize"><a href="#/GameAgent">代理加盟</a></el-menu-item>
      <el-menu-item index="3" :style="fontSize"><a href="#/SettledEnterprise">厂家入驻</a></el-menu-item>
      <!-- 游戏列表下拉菜单 -->
      <el-submenu index="4" :style="fontSize">
        <template slot="title"><span :style="fontSize">游戏列表</span></template>
        <el-menu-item index="4-1" :style="fontSize"><a @click="jump('/GameList?pingTai=安卓')">安卓</a></el-menu-item>
        <el-menu-item index="4-2" :style="fontSize"><a @click="jump('/GameList?pingTai=ios')">IOS</a></el-menu-item>
        <el-menu-item index="4-3" :style="fontSize"><a @click="jump('/GameList?pingTai=h5')">h5游戏</a></el-menu-item>
        <el-menu-item index="4-4" :style="fontSize"><a @click="jump('/GameList?pingTai=页游')">页游</a></el-menu-item>
      </el-submenu>
      <!-- 推广方案下拉菜单 -->
       <el-submenu index="5" :style="fontSize">
  <template slot="title">
    <span :style="fontSize">推广方案</span>
  </template>
  <el-menu-item 
    v-for="(item, index) in filteredTuiGuang" 
    :key="item.name" 
    :index="'5-' + index" 
    :style="fontSize"
    @click="jump('/PromotionPlan?way=' + item.name)"
  >
    {{ item.name }}
  </el-menu-item>
</el-submenu>
      <el-menu-item index="6" :style="fontSize"><a href="#/AboutUs">关于我们</a></el-menu-item>
      <el-menu-item index="7" :style="fontSize"><a href="#/NewsAndInformation">新闻资讯</a></el-menu-item>
      <!-- <el-menu-item index="8" :style="fontSize"><a href="#/MLogin">MLogin</a></el-menu-item> -->
    </el-menu>
  </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';

export default {
    created(){
        Bus.$on('pageCreating',()=>{
          this.init()
        })
        Bus.$on('GameListChose',()=>{
          this.activeIndex2 = '4'
        })
        Bus.$on('PromotionPlanChose',()=>{
          this.activeIndex2 = '5'
        })
    },
  data() {
    return {
      activeIndex2: '1',
      fontSize: "font-size: 18px;",
      tuiGuang: [],
    };
  },
  computed: {
    filteredTuiGuang() {
      return this.tuiGuang.filter(item => !item.hidden);
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      var config = {
      method: 'get',
      url: this.$hdAddress + "/selectAllCustomPages",
    };

    axios(config)
      .then(response => { // 使用箭头函数，这样就不需要 self
        this.tuiGuang = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    jump(url) {
      this.$router.push({ path: url }).catch(() => {}); // 使用 replace 避免后退到 MyInf
      this.$router.go(0);
    },
  }
}
</script>

<style scoped>
a {
  color: #fff;
}

/* 导航栏样式 */
.navbar {
  height: 6em;
  line-height: 4em;
  padding: 0 1.25em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar,
.el-menu--horizontal,
.el-menu {
  background-color: #545c6400;
}

/* Logo 图片样式 */
.logo img {
  margin-top: 2em;
  height: 100%;
}

/* 导航项样式 */
.navbar .el-menu-item,
.navbar .el-submenu__title {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.9375em;
  color: inherit;
  text-decoration: none;
}

/* 超链接 */
.el-menu--popup-bottom-start a {
  display: block;
  height: 100%;
  width: 100%;
}

/* 导航项悬停效果 */
.navbar .el-menu-item:hover,
.navbar .el-submenu__title:hover {
  background-color: #383e41;
}

/* 禁用菜单项样式 */
.navbar .el-menu-item.is-disabled {
  color: #ccc;
}

/* 子菜单样式 */
.navbar .el-menu--popup {
  background-color: #545c64;
  border: none;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
}

.navbar .el-menu--popup .el-menu-item {
  color: #fff;
}

.navbar .el-menu--popup .el-menu-item:hover {
  background-color: #383e41;
}
</style>